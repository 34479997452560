<template>
  <div>
    <v-container class="py-3 px-6">
      <v-row>
        <v-col cols="6" sm="6" md="3" v-for="(heading,i) in headings" :key="i">
          <v-card
              class="mx-auto px-5 pt-5 pb-2 elevation-25"
              max-width="400"
          >
            <v-icon :class="heading.class" :color="heading.color">
              {{  heading.icon }}
            </v-icon>

            <v-list-item style="min-height: 20px" class="mt-4 pr-0">
              <span :class="heading.color + '--text'">{{  heading.text }}</span>
            </v-list-item>
            <v-list-item style="min-height: 30px" class="mb-0 pr-0">
              <span class="text-h3 font-weight-bold">{{heading.value}}</span>
            </v-list-item>

          </v-card>
        </v-col>
      </v-row>
      <v-card class="elevation-25 my-3" min-height="200">
        <v-card-title>
          <h3 class="card-title pmd-title-separator">
            {{this.$t('$vuetify.counselorHomePage.upcomingSessions')}}
          </h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="next_four_sessions.length" class="main-container">
          <v-row justify="right" align="right">
            <v-col cols="12" sm="8" md="5" lg="4"
                   v-for="(session,index) in next_four_sessions"
                   :key="index">
              <SessionCard :mode="'doctor'" :factor="false" :session="session"></SessionCard>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="text-center" v-else>
          {{this.$t('$vuetify.sessionsPage.noSession')}}
        </v-card-text>
      </v-card>

      <!--<v-row justify="center">-->
      <!--<v-col cols="12" sm="6" md="3">-->
      <!--<v-card class="pa-5">-->
      <!--<v-card-text class="">-->
      <!--<v-row>-->
      <!--<v-col cols="8">کل جلسات برگزار شده</v-col>-->
      <!--<v-col cols="4">{{ dashboard.totalSessionCount }}</v-col>-->
      <!--</v-row>-->

      <!--</v-card-text>-->
      <!--</v-card>-->
      <!--</v-col>-->
      <!--&lt;!&ndash;<v-col cols="12" sm="6" md="3">&ndash;&gt;-->
      <!--&lt;!&ndash;<v-card class="pa-5">&ndash;&gt;-->
      <!--&lt;!&ndash;<v-card-text class="">&ndash;&gt;-->
      <!--&lt;!&ndash;<v-row>&ndash;&gt;-->
      <!--&lt;!&ndash;<v-col cols="8">مجموع درآمد</v-col>&ndash;&gt;-->
      <!--&lt;!&ndash;<v-col cols="4">{{ dashboard.totalSales }}</v-col>&ndash;&gt;-->
      <!--&lt;!&ndash;</v-row>&ndash;&gt;-->

      <!--&lt;!&ndash;</v-card-text>&ndash;&gt;-->
      <!--&lt;!&ndash;</v-card>&ndash;&gt;-->
      <!--&lt;!&ndash;</v-col>&ndash;&gt;-->
      <!--<v-col cols="12" sm="6" md="3">-->
      <!--<v-card class="pa-5">-->
      <!--<v-card-text class="">-->
      <!--<v-row>-->
      <!--<v-col cols="8">تعداد مراجعان</v-col>-->
      <!--<v-col cols="4">{{ dashboard.usersCount }}</v-col>-->
      <!--</v-row>-->

      <!--</v-card-text>-->
      <!--</v-card>-->
      <!--</v-col>-->
      <!--<v-col cols="12" sm="6" md="3">-->
      <!--<v-card class="pa-5">-->
      <!--<v-card-text>-->
      <!--<v-row>-->
      <!--<v-col cols="8">جلسات پیش رو</v-col>-->
      <!--<v-col cols="4">{{ dashboard.nextSessionsCount }}</v-col>-->
      <!--</v-row>-->
      <!--</v-card-text>-->
      <!--</v-card>-->
      <!--</v-col>-->
      <!--</v-row>-->
      <!--<counselor-sessions v-if="nextFiveSessions.length" :title="nextTitle" :five-sessions="nextFiveSessions"-->
      <!--is-five></counselor-sessions>-->
    </v-container>
  </div>
</template>

<script>
// import CounselorSessions from "../sessions/CounselorSessions";
import {EventBus} from "@/event-bus";
import SessionCard from '@/components/sessions/SessionCard'

export default {
  components: {
    // CounselorSessions,
    SessionCard
  },
  data() {
    return {
      headings: [
        {
          key: 'allSessions',
          text: this.$t('$vuetify.counselorHomePage.sessionsHeld'),
          icon: 'mdi-heart-box',
          value: 0,
          color: 'purple',
          class: 'pmd-icon-circle md-primary-circle'
        },
        {
          key: 'futureSessions',
          text: this.$t('$vuetify.counselorHomePage.upcomingSessions'),
          icon: 'mdi-monitor',
          value: 0,
          color: 'blue',
          class: 'pmd-icon-circle md-info-circle'
        },
        {
          key: 'messages',
          text: this.$t('$vuetify.counselorHomePage.messages'), icon: 'mdi-message', value: '0',
          color: 'gray',
          class: 'pmd-icon-circle md-normal-circle'
        },
        {
          key: 'patientsCount',
          text: this.$t('$vuetify.counselorHomePage.NumberOfClients'),
          icon: 'fas fa-users',
          value: 0,
          color: 'orange',
          class: 'pmd-icon-circle md-warning-circle'
        },
      ],
      search: '',
      headers: [
        {
          text: 'مراجعه کننده',
          align: 'start',
          sortable: false,
          value: 'customer',
        },
        {text: 'روز', value: 'jdayName'},
        {text: 'تاریخ', value: 'date'},
        {text: 'ساعت شروع', value: 'time'},
        {text: 'مدت زمان', value: 'period', sortable: false},
        {text: '', value: 'datetimeago'},
        {text: '', value: 'action', sortable: false},
      ],
      nextFiveSessions: [],
      dashboard: {
        totalSessionCount: '',
        totalSales: '',
        usersCount: '',
        nextSessionsCount: '',

      },
      next_four_sessions: [],
    }
  },
  computed: {
    nextTitle() {
      if (this.nextFiveSessions.length) {

        if (this.nextFiveSessions.length == 5) {
          return '5 جلسه پیش رو';
        }
        else {
          return this.dashboard.nextSessionsCount + ' جلسه پیش رو';
        }

      }
      return '';

    }
    // ...mapGetters({
    //     dashboard: 'getCounselorDashboard'
    // })
  },
  methods: {
    getHeading(key) {
      return this.headings.find((item) => item.key == key)
    },
    setHeadings(headings) {
      headings.forEach((heading) => {
        let hd = this.getHeading(heading.key);
        hd.value = heading.value;
      })
    }
  },
  created() {
    EventBus.$emit('setLoading', true);

    this.$store.dispatch('getCounselorDashboard').then((data) => {
      let dashboard = data.data;

      this.next_four_sessions = dashboard.next_four_sessions;

      this.setHeadings([
        {key: 'allSessions', value: dashboard.allSessions},
        {key: 'futureSessions', value: dashboard.nextSessionsCount},
        {key: 'messages', value: 0},
        {key: 'patientsCount', value: dashboard.allClients}
      ]);


      // this.dashboard.totalSessionCount = dashboard.allSessions;
      // this.dashboard.totalSales = dashboard.packagesCount;
      // this.dashboard.usersCount = dashboard.allClients
      // this.dashboard.nextSessionsCount = dashboard.nextSessionsCount;
      // this.nextFiveSessions = dashboard.nextFiveSessions;


      //
      // console.log('Dashboard Data: ', data);
      // this.dashboard = data.data;
      // this.nextFiveSessions = data.data.nextSessionsList;
      // this.nextFiveSessions.forEach(session => {
      //     session['date'] = session.jdatetime.split(' ')[0];
      //     session['time'] = session.jdatetime.split(' ')[1];
      //     session['period'] = '45 دقیقه';
      // })
    }).finally(() => {
      EventBus.$emit('setLoading', false);
    });
  }
}
</script>

<style scoped>

</style>