<template>
    <div style="min-height: 100vh;">
        <counselor-dashboard v-if="$store.getters.userHasRole('counselor')"></counselor-dashboard>
<!--        <admin-dashboard v-else-if="$store.getters.userHasRole('admin')"></admin-dashboard>-->
        <!--<client-dashboard v-else-if="$store.getters.userHasRole('customer')"></client-dashboard>-->
        <clientHomePage v-else-if="$store.getters.userHasRole('customer')"></clientHomePage>
    </div>
</template>

<script>
    /*eslint-disable*/

    import counselorDashboard from '@/components/dashboards/counselorDashboard.vue';
    // import adminDashboard from '@/components/dashboards/adminDashboard.vue';
    // import clientDashboard from '@/components/dashboards/clientDashboard.vue';
    import clientHomePage from '@/components/dashboards/clientHomePage.vue';

    export default {
        components: {
            counselorDashboard,
            // adminDashboard,
            // clientDashboard,
            clientHomePage
        },
        methods: {

        },
        created(){

            //WebEngage
            // send Attributes
            // webengage.user.setAttribute('we_email', 'john@doe.com');
            // webengage.user.setAttribute('we_birth_date', '1986-08-19');

            //Custom Event
            // webengage.track("Added To Cart", {
            //     "Product ID" : "AXK1337",
            //     "Price" : 39.80,
            // });
        }
    }
</script>

<style scoped>

</style>