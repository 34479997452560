<template>
  <v-container class="background-gray py-0 px-6">
    <clientDashboard></clientDashboard>
    <v-dialog
        v-model="addCycle"
        max-width="700"
    >
      <AddNewCycle @cycleAdded="getCycles" :dialog="true" @close="addCycle=false"></AddNewCycle>
    </v-dialog>
    <v-row>
      <v-col cols="6" sm="6" md="3" v-for="(heading,i) in headings" :key="i">
        <v-card
            class="mx-auto px-5 pt-5 pb-2 elevation-25"
            max-width="400"
        >
          <v-icon :class="heading.class" :color="heading.color">
            {{ heading.icon }}
          </v-icon>
          <v-list-item style="min-height: 20px" class="mt-4 pr-0">
            <span :class="heading.color + '--text'">{{ heading.text }}</span>
          </v-list-item>
          <v-list-item style="min-height: 30px" class="mb-0 pr-0">
            <span class="text-h3 font-weight-bold">{{ heading.value }}</span>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-1">
      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title class="pt-0 mt-0">
            <h3 class="card-title pmd-title-separator pt-5">
              {{ $t('$vuetify.requestSession') }}
            </h3>
          </v-card-title>
          <v-card-text class="pt-10" v-html="$t('$vuetify.dashboardPage.requestSessionDescription') ">
            <br/>
          </v-card-text>
          <v-card-actions>
            <v-btn block rounded class="warning">
                    <span class="title">
                        <span @click="openSessionRequestDialog">
                          {{ $t('$vuetify.requestSession') }}
                        </span>
                    </span>
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card>
          <v-card-title class="pt-0 mt-0">
            <h3 class="card-title pmd-title-separator pt-5">
             {{$t('$vuetify.addCycle')}}
            </h3>
          </v-card-title>
          <v-card-text class="pt-10" v-html="$t('$vuetify.dashboardPage.addCycleDescription')">
            <br/>
          </v-card-text>
          <v-card-actions>
            <v-btn block rounded class="primary" @click="addCycle=true">
                    <span class="title">
                        <span> {{$t('$vuetify.addCycle')}}</span>
                    </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="5" lg="4"
             v-for="(session,index) in $store.getters.getUserInfo.four_future_sessions"
             :key="index">
        <SessionCard :session="session"></SessionCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import SessionCard from '@/components/sessions/SessionCard'
import {EventBus} from "@/event-bus";
import AddNewCycle from '@/components/clients/AddNewCycle';
import clientDashboard from '@/components/dashboards/clientDashboard.vue';

export default {
  components: {SessionCard, AddNewCycle, clientDashboard},
  computed: {
    ...mapGetters({
      profile: 'getUserInfo',
      newMessageCount: 'getNewMessageCount'
    })
  },
  methods: {
    getCycles() {
      this.$store.dispatch('getUserInfo');
    },
    openSessionRequestDialog() {
      EventBus.$emit('showAddSessionDialog');
    },
  },
  data() {
    return {
      addCycle: false,

      headings: [
        {
          text: this.$t('$vuetify.clientHomePage.counselingCourses'),
          icon: 'mdi-heart-box',
          value: this.$store.getters.getUserInfo.cycles.length,
          color: 'purple',
          class: 'pmd-icon-circle md-primary-circle'
        },
        {
          text: this.$t('$vuetify.clientHomePage.upcomingSessions'),
          icon: 'mdi-monitor',
          value: this.$store.getters.getUserInfo.future_session_count,
          color: 'blue',
          class: 'pmd-icon-circle md-info-circle'
        },
        {
          text: this.$t('$vuetify.clientHomePage.messages'),
          icon: 'mdi-message',
          value: '0',
          color: 'gray',
          class: 'pmd-icon-circle md-normal-circle'
        },
        {
          text: this.$t('$vuetify.clientHomePage.packages'),
          icon: 'fas fa-heartbeat',
          value: this.$store.getters.getUserInfo.wallet.total,
          color: 'orange',
          class: 'pmd-icon-circle md-warning-circle'
        },
      ]
    }
  },

}
</script>